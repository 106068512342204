<template>
  <el-container>
    <patient-new-form v-model="showNewPatientForm" :patient_id="newPatientId" @after-save="setPatientForSearchForm"></patient-new-form>
    <patient-form v-model="showPatientForm" :mode="showPatientFormMode" routerreturnname="study_dashboard"></patient-form>
    <contact-form v-model="showContactForm"></contact-form>
    <survey-patient-form-modal :survey-patient="currentSurveyPatient" v-model="showSurveyPatientForm"></survey-patient-form-modal>
    <survey-patient-task-form-modal :surveytask="currentSurveyTask" v-model="showSurveyPatientTaskForm" @after-save="onTaskListClick"></survey-patient-task-form-modal>
    <survey-task-group-form-modal :surveytaskgroup="currentSurveyTaskGroup" v-model="showSurveyTaskGroupForm" @after-save="onTaskListClick"></survey-task-group-form-modal>

    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="11" :sm="9" :span="7" :xl="4" :lg="6">
          <el-button-group>

            <el-tooltip
                content="Kontakt / Supportanfrage"
                placement="bottom"
            >
              <el-button @click="showContactForm = true" icon="el-icon-chat-line-square" plain></el-button>
            </el-tooltip>

            <el-tooltip
                content="Patient aus AIS holen"
                placement="bottom"
            >
              <el-button @click="getPatientFromAIS" icon="el-icon-caret-bottom" plain></el-button>
            </el-tooltip>


            <el-tooltip
              :content="(currentPatient == null ? 'Proband hinzufügen' : 'Proband Stammdaten')"
              placement="bottom"
            >
              <el-button @click="onPatientIconClick" icon="el-icon-user-solid" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Einstellungen"
              placement="bottom"
            >
              <el-button @click="openWindow('setting')" icon="el-icon-setting" plain></el-button>
            </el-tooltip>

          </el-button-group>
        </el-col>
        <el-col :xs="9" :sm="11" :span="9" :xl="6" :lg="10">
          <patient-search :patient_id="currentPatientId"></patient-search>
        </el-col>
        <el-col :xs="0" :sm="0" :span="2" :xl="8" :lg="4">
          &nbsp;
        </el-col>
        <el-col :span="2" style="line-height:50px; text-align:right">
          <el-button @click="navigateToStart" class="mr-3 pl-3 pr-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
        </el-col>
        <el-col :sm="2" :span="2" class="text-right align-content-end">
          <main-menu></main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main>

      <el-card class="box-card">

      <el-tabs type="card" v-model="currentTabPane">
        <el-tab-pane label="Studien">
          <el-row>
            <el-col :span="20">
              <h5><span v-if="showActiveSurveys">Aktive</span><span v-else>Alle</span> Studien <span v-if="currentPatientId">von {{fullInfo}}  <el-link type="warning" @click="resetCurrentPatient" icon="el-icon-delete" :underline="false">Filter entfernen</el-link></span></h5>
              <div v-if="currentPatientId">
                <el-button type="primary" size="small" @click="onBtnAddPatientToSurvey" >Proband einer Studie hinzufügen</el-button>
              </div>
            </el-col>
            <el-col class="text-right" :span="4">
              <el-button type="primary" size="small" @click="onBtnShowToggle" v-bind:class="{ 'is-active': showActiveSurveys }"><span v-if="showActiveSurveys">Alle</span><span v-else>Aktive</span> Studien anzeigen</el-button>

            </el-col>
          </el-row>

          <survey-table :data="surveysFiltered" clickable expandable :showfilter="false" @after-save="loadSurveys"></survey-table>
        </el-tab-pane>
        <el-tab-pane label="Aufgabenliste">

          <h5>Aufgabenliste <span v-if="currentPatientId">von {{fullInfo}}  <el-link type="warning" @click="resetCurrentPatient" icon="el-icon-delete" :underline="false">Filter entfernen</el-link></span> </h5>

          <el-row>
            <el-col :span="3">
              <el-date-picker
                class="w-100"
                v-model="currentTaskFilterDate"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Datum auswählen">
              </el-date-picker>
            </el-col>
            <el-col :span="8">
              <el-button-group>
                <el-button type="primary" @click="setTaskFilterDateToday" class="mr-1">Heute</el-button>
              </el-button-group>

            </el-col>
            <el-col class="text-right" :span="13">
              <el-button-group>
                <el-tooltip
                  content="Liste"
                  placement="bottom"
                >
                  <el-button @click="surveyTaskView = 'list'" type="primary" ><i class="el-icon-s-fold p-0 m-0" style="font-size:0.9em;"></i></el-button>
                </el-tooltip>
                <el-tooltip
                  content="Kalender"
                  placement="bottom"
                >
                  <el-button @click="surveyTaskView = 'calendar'" type="primary" ><i class="el-icon-s-grid p-0 m-0" style="font-size:0.9em;"></i></el-button>
                </el-tooltip>
              </el-button-group>
              <el-button type="primary" class="ml-3" @click="openPdfWindow">Drucken</el-button>
            </el-col>
          </el-row>

          <Calendar ref="calendar" v-if="surveyTaskView == 'calendar'" class="mt-5" :events="surveyTaskGroupsCalendar" :clickhandler="openSurveyPatientTaskForm" :key="calendarRenderKey"></Calendar>

          <el-table v-if="surveyTaskView == 'list'"
            :data="surveyTaskGroups"
            style="width: 100%"
            :empty-text = "(currentTaskFilterDate ? 'Keine Aufgaben am ' + $filters.germanDate(currentTaskFilterDate) : 'Keine Aufgaben vorhanden')"
            @row-click="openSurveyPatientTaskForm"
            >
            <el-table-column
              prop="date"
              label="Datum"
              width="120">
              <template #default="scope">
                <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Aufgabe">
              <template #default="scope">
                <span v-if="scope.row.surveytask">{{ scope.row.surveytask.title }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Proband">
              <template #default="scope">
                <span v-if="scope.row.patient">{{ scope.row.patient.name }}, {{ scope.row.patient.firstname }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Studie"
              prop="survey.title_short"
              sortable>
              <template #default="scope">
                <span v-if="scope.row.survey">{{ scope.row.survey.title_short }}</span>
              </template>
            </el-table-column>


          </el-table>


        </el-tab-pane>
        <el-tab-pane label="Abfragen">

          <el-radio-group class="mb-3" v-model="currentStatsTab">
            <el-radio-button label="patients">Studienrekrutierung</el-radio-button>
            <el-radio-button label="income">Einnahmen</el-radio-button>
            <el-radio-button label="feasibility">Feasibility</el-radio-button>
          </el-radio-group>

          <patients-table v-if="currentStatsTab == 'patients'" :filterable="true"></patients-table>
          <survey-patient-tasks-table v-if="currentStatsTab == 'income'"></survey-patient-tasks-table>
          <survey-stats-feasibility v-if="currentStatsTab == 'feasibility'"></survey-stats-feasibility>
        </el-tab-pane>

      </el-tabs>





      </el-card>

    </el-main>
  </el-container>
</template>

<script>
// import PatientEcog from "@/components/PatientEcog";
import PatientNewForm from "@/components/patient/PatientNewForm";
import PatientForm from "@/components/patient/PatientForm";
import ContactForm from "@/components/parts/ContactForm";
import SurveyPatientFormModal from "@/components/survey/SurveyPatientFormModal";

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import MainMenu from "@/components/parts/MainMenu";
import SurveyTable from "../components/parts/SurveyTable";
import _ from "lodash";
import SurveyPatientTaskFormModal from "../components/survey/SurveyPatientTaskFormModal";
import moment from "moment";
import PatientsTable from "../components/parts/PatientsTable";
import SurveyTaskGroupFormModal from "../components/survey/SurveyTaskGroupFormModal.vue";
import Calendar from "../components/parts/Calendar.vue";
import SurveyPatientTasksTable from "../components/parts/SurveyPatientTasksTable.vue";
import SurveyStatsFeasibility from "../components/survey/SurveyStatsFeasibility.vue";

export default {
  name: "Studies",
  props: [],
  components: {
    SurveyStatsFeasibility,
    SurveyPatientTasksTable,
    Calendar,
    SurveyTaskGroupFormModal,
    PatientsTable,
    SurveyPatientTaskFormModal,
    SurveyTable,
    MainMenu,
    // PatientEcog,
    PatientNewForm,
    PatientForm,
    ContactForm,
    SurveyPatientFormModal
  },
  data () {
    return {
      currentPatientId: null,
      newPatientId: null,
      showNewPatientForm: false,
      showPatientForm: false,
      showContactForm: false,
      showActiveSurveys: true,
      currentTabPane: 0,
      showSurveyPatientForm: false,
      currentTaskFilterDate: null,
      currentTaskFilterUsers: [],
      currentSurveyPatient: null,
      currentSurveyTask: null,
      showSurveyPatientTaskForm: false,
      showNewTasksForm: false,
      allSurveys: [],
      currentSurveyTaskGroup: {},
      showSurveyTaskGroupForm: false,
      surveyTaskView: 'list',
      calendarRenderKey: 0,
      currentStatsTab: 'patients'
    }
  },
  mounted: async function () {
    this.loadSurveys()
    this.getSurveyTasks()
    this.getSurveyActivities()
    this.getUsers()
  },
  computed: {
    ...mapGetters('patients', ['fullInfo', 'currentPatient', 'unusedFindings']),
    ...mapGetters('surveys', ['surveys', 'patientSurveyTasks', 'surveyTaskGroups', 'surveyTasks', 'surveyActivities', 'patientSurveyActivityById']),
    ...mapGetters('users', ['users']),
    showPatientFormMode: function () {
      return (this.currentPatient == null ? 'new' : 'current')
    },
    surveysFiltered: function () {
      if(!this.allSurveys) return []

      let filteredSurveys
      if(this.showActiveSurveys) {
        filteredSurveys = _.filter(this.allSurveys, (o) => (o.status_id == 2 || o.status_id == 5))
      }
      else {
        filteredSurveys = this.allSurveys
      }

      if(this.currentPatientId !== null) {
        filteredSurveys = _.filter(filteredSurveys, (o) => _.find(o.patients, (p) => p.patient_id == this.currentPatientId))
      }

      return filteredSurveys
    },
    surveyTaskGroupsCalendar: function() {

      let groups = []
      for(const row of this.surveyTaskGroups) {

          let title = row.surveytask.title + ' (' + row.patient.name + ')'

          groups.push({
            id: row.id,
            title: title,
            start: row.date,
            task: row
          })

      }

      return groups

    }
  },
  watch: {
    'currentPatient': function(patient) {
      this.currentPatientId = patient ? patient.id : null
    },
    'currentTabPane': function(tabIndex) {
      if(tabIndex == 1) {
        this.onTaskListClick()
      }
    },
    'currentTaskFilterUsers': function() {
      this.loadSurveyTaskGroups()
    },
    'currentTaskFilterDate': function() {
      this.loadSurveyTaskGroups()
    }
  },
  methods: {
    ...mapActions('patients', ['reloadCurrentPatient', 'setCurrentPatient', 'resetCurrentPatient']),
    ...mapActions('surveys', ['getSurveys', 'getSurveyTaskGroups', 'getPatientSurveyTasks', 'getSurveyTasks', 'getSurveyActivities']),
    ...mapActions('users', ['getUsers']),
    async loadSurveys() {
      await this.getSurveys()
      this.allSurveys = _.cloneDeep(this.surveys)
    },
    async getPatientFromAIS() {
      const result = (await axios({ url: 'gdt', baseURL: 'http://127.0.0.1:3333' })).data
      if(result.error === false) {
        const patient = await this.setCurrentPatient(result.patient_id)
        if(patient === null) {
          this.newPatientId = result.patient_id
          this.showNewPatientForm = true
        }
        else {
          this.currentPatientId = result.patient_id
        }
      }
      else {
        this.$notify.error({
          message: "Ein Fehler trat bei der Kommunikation mit dem AIS auf",
          position: 'bottom-left'
        });
      }

    },
    setPatientForSearchForm() {
      this.currentPatientId = this.currentPatient.id
    },
    onBtnShowToggle() {
      this.showActiveSurveys = !this.showActiveSurveys
    },
    openWindow(path) {
      if(path === 'setting') {
        this.$root.openNewWindow('/app/study/setting')
      }
      else if(this.currentPatient) {
        this.$root.openNewWindow('/app/patient/' + this.currentPatient.id + '/' + path)
      }
    },
    async onTaskListClick() {
      // this.getPatientSurveyTasks( { patient_id: 0, survey_local_id: 0 })
      await this.loadSurveyTaskGroups()
    },
    onPatientIconClick() {
      this.showPatientForm = true
    },
    onBtnAddPatientToSurvey() {
      this.currentSurveyPatient = this.currentPatient
      this.showSurveyPatientForm = true
    },
    openSurveyPatientTaskForm(row) {

      if(typeof row === 'number') {
        const exist = _.find(this.surveyTaskGroups, (o) => o.id == row)
        if(exist) {
          row = exist
        }
      }
      this.currentSurveyTask = row
      this.showSurveyPatientTaskForm = true
    },
    setTaskFilterDateToday() {
      this.currentTaskFilterDate = moment().format("YYYY-MM-DD")
    },
    async openPdfWindow() {

      const date = this.currentTaskFilterDate ? this.currentTaskFilterDate : ''
      const patient = this.currentPatientId ? this.currentPatientId : ''

      if(window.gui) {
        // @TODO should be signed url for one time access
        window.gui.send('print-file', { url: axios.defaults.baseURL + '/export/surveys/patients/tasks/?date=' + date + '&patient=' + patient, token: localStorage.getItem("dacy-token") })
      }
      else {
        const pdfData = (await axios.get('/export/surveys/patients/tasks/?date=' + date + '&patient=' + patient, {
          responseType: 'blob'
        })).data
        const url = window.URL.createObjectURL(new Blob([pdfData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dacy_survey_patients_task_list.pdf');
        document.body.appendChild(link);
        link.click();
      }

    },
    openTaskGroupWindow() {
      this.currentSurveyTaskGroup = null
      this.showSurveyTaskGroupForm = true
    },
    navigateToStart() {
      this.$router.push({ name: 'dashboard' } )
    },
    taskListRowClassName(scope) {
      if(scope.row.surveytaskgroup) return 'bg-white'
      return 'bg-surveytaskgroup'
    },
    async loadSurveyTaskGroups() {
      await this.getSurveyTaskGroups(
        {
          date: this.currentTaskFilterDate ? this.currentTaskFilterDate : null,
          users: this.currentTaskFilterUsers ? this.currentTaskFilterUsers : null
        }
      )
      this.calendarRenderKey++
    }
}

}
</script>
<style>
.bg-surveytaskgroup {
  background-color:#EEE !important;
  outline: 1px solid #DDD;
}

</style>
