<template>
  <div>
    <el-row>
      <el-col :span="5" class="mr-1">
        <h6>Datum von</h6>
        <el-date-picker
          v-model="form.date_from"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="5" :offset="1" class="mr-1">
        <h6>Datum bis</h6>
        <el-date-picker
          v-model="form.date_to"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="11">
        <h6>Diagnose</h6>
        <el-select size="medium" class="w-100" v-model="form.diagnosis" filterable>
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
            v-for="item in allDiagnosis"
            :key="item.id"
            :label="(item.code + ', ' + item.title)"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="12">
        <h6>Behandlungssituation / Therapiemodalität</h6>
        <el-select size="medium" class="w-100" v-model="form.treatmentsituation" filterable>
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
            v-for="item in allTreatmentSituations"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="11">
        <h6>Biomarkermuster</h6>
        <el-select size="medium" class="w-100" v-model="form.biomarker" filterable >
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
            v-for="item in allBiomarker"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="12">
        <h6>Protokoll</h6>
        <el-select size="medium" class="w-100" v-model="form.protocols" multiple filterable >
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
            v-for="item in protocols"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-button class="mt-4" type="warning" @click="loadPatients"><i v-if="isLoading" class="el-icon el-icon-loading"></i> Patienten anzeigen</el-button>

    <patients-table class="mt-3" :columns="['id', 'name', 'birthdate']" :filterable="false" :clickable="true" @on-row-click="onPatientClick" :data="patients" :deleteable="false" :expandable="false"></patients-table>

  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import axios from "axios";
import PatientsTable from "../parts/PatientsTable";
import _ from "lodash";

export default {
  name: "SurveyStatsFeasibility",
  components: {
    PatientsTable
  },
  data() {
    return {
      isLoading: false,
      allTreatmentSituations: [],
      allDiagnosis: [],
      allBiomarker: [],
      patients: [],
      form: {
        date_from: moment().subtract('1', 'years').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        therapies: [],
        diagnosis: null,
        treatmentsituation: null,
        biomarker: null,
        protocols: []
      },
    }
  },
  mounted: async function () {
    await this.getTherapies(this.form)
    await this.getOncologyDiagnosis()
    await this.getBiomarker()
    await this.getTreatmentsituations()
    await this.getProtocols()


    this.allDiagnosis = {...this.oncologyDiagnosis}
    this.allTreatmentSituations = {...this.treatmentsituations}
    this.allBiomarker = {...this.biomarker}
  },
  computed: {
    ...mapState('therapies', ['therapies']),
    ...mapState('diagnosis', ['oncologyDiagnosis']),
    ...mapState('biomarker', ['biomarker']),
    ...mapState('protocols', ['protocols']),
    ...mapState('treatmentsituations', ['treatmentsituations']),

  },
  watch: {
    'form.diagnosis': async function () {
      this.form.treatmentsituation = null
      this.form.biomarker = null
      await this.getTherapies(this.form)
      this.filterTreatmentSituations()
      this.filterBiomarker()
    },
    'form.treatmentsituation': async function () {
      if(this.form.treatmentsituation !== null) {
        this.form.biomarker = null
        await this.getTherapies(this.form)
        this.filterBiomarker()
      }
    },
    'form.biomarker':async function () {
      if(this.form.biomarker !== null) {
        await this.getTherapies(this.form)
      }
    }
  },
  methods: {
    ...mapActions('diagnosis', ['getDiagnosis', 'getOncologyDiagnosis']),
    ...mapActions('treatmentsituations', ['getTreatmentsituations']),
    ...mapActions('biomarker', ['getBiomarker']),
    ...mapActions('therapies', ['getTherapies']),
    ...mapActions('protocols', ['getProtocols']),
    filterTreatmentSituations() {
      let filteredTreatmentSituations = []
      let addedTreatmentSituations = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.treatmentSituation && addedTreatmentSituations.indexOf(therapy.treatmentSituation.id) === -1) {
          addedTreatmentSituations.push(therapy.treatmentSituation.id)
          filteredTreatmentSituations.push(therapy.treatmentSituation)
        }

      }
      this.allTreatmentSituations = filteredTreatmentSituations

    },
    filterBiomarker() {
      let filteredBiomarker = []
      let addedBiomarker = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.biomarker && therapy.biomarker.length) {
          for(const biomarker of therapy.biomarker) {
            if(addedBiomarker.indexOf(biomarker.id) === -1) {
              addedBiomarker.push(biomarker.id)
              filteredBiomarker.push(biomarker)
            }
          }
        }

      }

      this.allBiomarker = _.sortBy(filteredBiomarker, ['title'])

    },
    async loadPatients() {
      this.patients = []
      this.isLoading = true
      let param = '?date_from=' + this.form.date_from + "&date_to=" + this.form.date_to
      if(this.form.diagnosis) param += '&diagnosis='+this.form.diagnosis
      if(this.form.treatmentsituation) param += '&treatmentsituation='+this.form.treatmentsituation
      if(this.form.biomarker) param += '&biomarker='+this.form.biomarker
      if(this.form.protocols) param += '&protocol='+this.form.protocols.join(',')
      this.patients = (await axios.get('/stats/surveys/patients/feasibility/'+param)).data
      this.isLoading = false
    },
    async onPatientClick(row) {
      window.open('/app/dashboard?patient='+row.id, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
    }
  }
}
</script>
<style scoped>
</style>
